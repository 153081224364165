// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Ajouter-module--ButtonWrapper--6I6KV";
export var DescriptionWrapper = "Ajouter-module--DescriptionWrapper--Bifdr";
export var FormWrapper = "Ajouter-module--FormWrapper---EVOb";
export var ImageWrapper = "Ajouter-module--ImageWrapper--m-Lb-";
export var InputWrapper = "Ajouter-module--InputWrapper--bdR+c";
export var NoImageWrapper = "Ajouter-module--NoImageWrapper--U3jHf";
export var Rounded = "Ajouter-module--Rounded--2RoUV";
export var RowWrapper = "Ajouter-module--RowWrapper--remId";
export var Spacer = "Ajouter-module--Spacer--1LLd8";
export var Wrapper = "Ajouter-module--Wrapper--ye6fW";