import React, { useState } from "react"
import {
  Wrapper,
  ImageWrapper,
  Rounded,
  NoImageWrapper,
  DescriptionWrapper,
  FormWrapper,
  RowWrapper,
  InputWrapper,
  Spacer,
  ButtonWrapper,
} from "./Ajouter.module.css"
import { OrangeTitle } from "../../../../css/Titre.module.css"
import {
  PaddingBottom10,
  PaddingTop30,
} from "./../../../../css/Padding.module.css"
import Button from "../../../../components/Button"
import { classNames } from "../../../../helpers/classNames"
import AddImage from "./../../../../res/add_image.png"
import Select from "../../../../components/Select"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Ajouter = () => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const [image, setImage] = useState({ preview: "", raw: "" })
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [category, setCategory] = useState("")
  const [description, setDescription] = useState("")
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [schedule, setSchedule] = useState(["", "", "", "", "", "", ""])

  const categories = [
    "Bars & Restauration",
    "Sports & Loisirs",
    "Mode & Bien être",
    "Services",
    "Jobs",
  ]

  const handleValidateFormButton = () => {
    if (
      image.preview === "" ||
      name.length === 0 ||
      category.length === 0 ||
      latitude < -90 ||
      latitude > 90 ||
      longitude < -180 ||
      longitude > 180 ||
      address.length === 0 ||
      description.length === 0 ||
      schedule.length !== 7 ||
      schedule[0].length === 0 ||
      schedule[1].length === 0 ||
      schedule[2].length === 0 ||
      schedule[3].length === 0 ||
      schedule[4].length === 0 ||
      schedule[5].length === 0 ||
      schedule[6].length === 0
    ) {
      setErrorSnackbarIsOpened(true)
      return
    }

    firebase
      .storage()
      .ref(`/shops/${image.raw.name}`)
      .put(image.raw)
      .then(uploadSuccess => {
        firebase
          .storage()
          .ref(`/shops/${image.raw.name}`)
          .getDownloadURL()
          .then(imageUrl => {
            let newShop = {
              address: address,
              category: category.value,
              description: description,
              image: imageUrl.toString(),
              latLng: new firebase.firestore.GeoPoint(latitude, longitude),
              name: name,
              rates: [],
              schedule: schedule,
              ourFavoriteIndex: 0,
            }
            firebase
              .firestore()
              .collection("shops")
              .add(newShop)
              .then(() => {
                setSuccessSnackbarIsOpened(true)
              })
              .catch(addingError => {
                //console.log("addingError: ", addingError)
              })
          })
          .catch(imageUrlError => {
            //console.log("imageUrlError: ", imageUrlError)
          })
      })
      .catch(uploadUrlError => {
        //console.log("uploadUrlError: ", uploadUrlError)
      })
  }

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Ajouter un partenaire</h1>
      <p className={DescriptionWrapper}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in libero
        tincidunt, consectetur nulla ut, condimentum libero. Fusce et elementum
        sapien. Vivamus cursus sapien non dolor aliquet ullamcorper. Maecenas
        quis aliquam tellus. Fusce ac cursus lacus. Proin sodales nisl non augue
        dapibus suscipit. Vestibulum eu mattis augue. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed
        vitae arcu sit amet metus auctor pellentesque sit amet nec ante.
        Suspendisse sed diam ultrices, elementum felis in, blandit turpis.
        Nullam cursus ligula quis leo tincidunt bibendum.
      </p>
      <form className={FormWrapper}>
        {/*gestion d'erreur, juste jpeg, jpg, png*/}
        <div className={ImageWrapper}>
          <label htmlFor="upload-button">
            {image.preview ? (
              <img className={Rounded} src={image.preview} alt="shop" />
            ) : (
              <div className={NoImageWrapper}>
                <img
                  src={AddImage}
                  alt="shop"
                  width="75"
                  height="75"
                  style={{ paddingLeft: "37px", paddingTop: "37px" }}
                />
              </div>
            )}
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="name">Nom</label>
            </div>
            <input
              type="name"
              placeholder="ex: Burger King"
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="address">Adresse</label>
            </div>
            <input
              type="name"
              placeholder="ex: 1 Avenue du Prado, 13006 Marseille"
              onChange={e => setAddress(e.target.value)}
            />
          </div>
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="category">Catégorie</label>
            </div>
            <Select
              placeholder="Sélectionnez une catégorie"
              options={categories.map(data => ({
                value: data,
                label: data,
              }))}
              onChange={e => setCategory(e)}
              value={category}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="schedules">Horaires</label>
            </div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  e.target.value,
                  schedule[1],
                  schedule[2],
                  schedule[3],
                  schedule[4],
                  schedule[5],
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  e.target.value,
                  schedule[2],
                  schedule[3],
                  schedule[4],
                  schedule[5],
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  schedule[1],
                  e.target.value,
                  schedule[3],
                  schedule[4],
                  schedule[5],
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  schedule[1],
                  schedule[2],
                  e.target.value,
                  schedule[4],
                  schedule[5],
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  schedule[1],
                  schedule[2],
                  schedule[3],
                  e.target.value,
                  schedule[5],
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  schedule[1],
                  schedule[2],
                  schedule[3],
                  schedule[4],
                  e.target.value,
                  schedule[6],
                ]
                setSchedule(newSchedule)
              }}
            />
            <div className={PaddingBottom10}></div>
            <input
              type="text"
              placeholder="ex: '8h - 18h' ou 'fermé'"
              onChange={e => {
                const newSchedule = [
                  schedule[0],
                  schedule[1],
                  schedule[2],
                  schedule[3],
                  schedule[4],
                  schedule[5],
                  e.target.value,
                ]
                setSchedule(newSchedule)
              }}
            />
          </div>
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="latitude">Latitude</label>
            </div>
            <input
              type="number"
              placeholder="ex: 43.576786778"
              onChange={e => setLatitude(Number(e.target.value))}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="longitude">Longitude</label>
            </div>
            <input
              type="number"
              placeholder="ex: 43.576786778"
              onChange={e => setLongitude(Number(e.target.value))}
            />
          </div>
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="description">Description</label>
          </div>
          <textarea
            type="message"
            placeholder="Décris le partenaire ici..."
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className={classNames([PaddingTop30, RowWrapper])}>
          <div className={Spacer}></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleValidateFormButton()}>
              Ajouter
            </Button>
          </div>
        </div>
      </form>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as ajouté un nouveau commerce !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue lors de l'ajout du commerce. Vérifie
          les informations que tu as rentré.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Ajouter
